<template>
  <div class="seal">
    <a-table v-if="data" :columns="columns" :dataSource="data">
      <template slot="SealImage" slot-scope="SealImage">
        <div v-viewer>
          <img style="width: 50px" :src="SealImage" alt="" />
        </div>
      </template>
      <template slot="value" slot-scope="value">
        <div
          v-if="
            value != '4ae815d133588c71013358ad96450007' &&
              value != '20887771a413daea180506ddccdfc936'
          "
          style="    display: inline-flex;"
        >
          <a-button class="upload">
            <a-icon type="upload" /> 上传公章
            <input
              :id="'upload_' + value"
              class="upload_bth"
              accept=".jpg,.png,.gif,.jpeg"
              type="file"
              @change="_upload($event, value)"
            />
          </a-button>

          <a-popconfirm
            placement="bottom"
            cancelText="取消"
            okText="确定"
            @confirm="del(value)"
            @cancel="maxClassify = -1"
          >
            <template slot="title">
              <p>确定删除么？</p>
            </template>
            <a-button style="margin-left: 15px">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
Vue.use(Viewer)
export default {
  name: 'Seal',
  data () {
    return {
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      data: '',
      columns: [
        {
          title: '名称',
          dataIndex: 'title',
          key: 'key'
        },
        {
          title: '公章',
          dataIndex: 'SealImage',
          width: '50',
          scopedSlots: { customRender: 'SealImage' },
          key: 'key'
        },
        {
          title: '操作',
          dataIndex: 'value',
          width: '30%',
          scopedSlots: { customRender: 'value' },
          key: 'key'
        }
      ]
    }
  },
  created () {
    this.LoadData()
  },
  methods: {
    LoadData () {
      let self = this
      this.$axios.get(8008, {}, res => {
        console.log(res)
        if (res.data.code == 1) {
          self.data = res.data.data
        }
      })
    },
    _upload (e, name) {
      let files = document.getElementById('upload_' + name).files
      let type = files[0].type
      let SealImage = ''
      let self = this
      if (type.indexOf('image/') != 0) {
        this.$message.error('只允许上传jpg、gif、png、jpeg格式的图片')
        return false
      }
      this.__uploadFile__(files).then(res => {
        if (res) {
          SealImage = res[0]['FileUrl']
          self._save(SealImage, name)
        }
      })
    },
    _save (SealImage, name) {
      let self = this
      self.$axios.post(
        1059,
        {
          SealImage: SealImage,
          Id: name,
          uid: self.userinfo.uid,
          username: self.userinfo.name
        },
        result => {
          if (result.data.code == 1) {
            self.$message.success(result.data.message)
            self.LoadData()
          } else {
            self.$message.error(result.data.message)
          }
        }
      )
    },
    del (id) {
      let self = this
      this.$axios.post(
        1059,
        {
          SealImage: 1,
          Id: id,
          uid: self.userinfo.uid,
          username: self.userinfo.name
        },
        result => {
          console.log(result)
          if (result.data.code == 1) {
            self.$message.success(result.data.message)
            self.LoadData()
          } else {
            self.$message.error(result.data.message)
          }
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.seal {
  padding: 27px 23px;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  background: #ffffff;
  position: relative;
  min-height: 930px;
}
.upload {
  display: block;
  position: relative;
  cursor: pointer;

  .upload_bth {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
